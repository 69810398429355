<template>
  <v-card
    :color="level"
    :variant="variant || 'tonal'"
    class="mb-2"
  >
    <v-row
      no-gutters
      align="center"
      justify="center"
    >
      <v-col
        cols="1"
        class="pa-2 ma-2"
      >
        <v-icon
          icon="mdi-information-slab-box"
          size="small"
        />
      </v-col>
      <v-col class="pa-2">
        <!-- eslint-disable vue/no-v-html -->
        <small
          class="font-italic"
          v-html="text"
        />
        <div>
          <slot />
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
export default {
    props : {
        text : { type : String, required : true },
        variant : { type : String, required : false },
        level : { type : String, required : false, default : "info" },
    }
}
</script>
